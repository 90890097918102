import React, { Fragment, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Heading,
  HStack,
  Icon,
  ScaleFade,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { api, percent } from "lib";
import { useFetchData, useCustomToast } from "hooks";
import { SocketContext } from "SocketProvider";
import { MdBlock, MdCheck, MdClose, MdError } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import InvoiceCapturesContext from "./context";

const QueueContext = createContext();

const InProgress = ({ item }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const processed = useMemo(() => {
    const response = { invoicesCounters: 0, electricityBalancesCounters: 0 };
    for (const counter of ["invoicesCounters", "electricityBalancesCounters"])
      for (const key of ["fulfilled", "ignored", "rejected"]) response[counter] += item[counter][key];
    return response;
  }, [item]);
  const toast = useCustomToast();

  const handleSaveData = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      await api.patch(`/private/invoice-captures/${item._id}/abort`);
      onClose();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [item._id, onClose, toast]);

  return (
    <Fragment>
      <HStack p="30px" bg="blue.500" borderRadius="xl" color="white">
        <CircularProgress isIndeterminate size="60px" thickness="4px" color="blue.700" />
        <Box flex="1" color="white">
          <Heading size="sm">
            #{item.nid} • {item.title}
          </Heading>
          <Text fontSize="xs">A captura de faturas está em progresso. Por favor aguarde.</Text>
          <HStack mt={1}>
            <Text fontSize="xs" bg="whiteAlpha.200" px="10px" py="2px" borderRadius="lg">
              <strong>{item.currentFileCount.toLocaleString()}</strong> de {item.filesCount.toLocaleString()} arquivos XML
            </Text>
            <Text fontSize="xs" bg="whiteAlpha.200" px="10px" py="2px" borderRadius="lg">
              <strong>{processed.electricityBalancesCounters.toLocaleString()}</strong> de{" "}
              {item.electricityBalancesCounters.size.toLocaleString()} saldos
            </Text>
            <Text fontSize="xs" bg="whiteAlpha.200" px="10px" py="2px" borderRadius="lg">
              <strong>{processed.invoicesCounters.toLocaleString()}</strong> de {item.invoicesCounters.size.toLocaleString()} faturas
            </Text>
          </HStack>
        </Box>
        <Button size="sm" colorScheme="whiteAlpha" leftIcon={<Icon as={MdBlock} />} isLoading={isLoadingSaveData} onClick={onOpen}>
          abortar
        </Button>
      </HStack>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente Abortar a captura de faturas em progresso?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onClose}>cancelar</Button>
            <Button colorScheme="yellow" onClick={handleSaveData} isLoading={isLoadingSaveData}>
              Abortar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

const Waiting = ({ item }) => {
  const { setQueue } = useContext(QueueContext);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const toast = useCustomToast();

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/private/invoice-captures", { data: [item._id] });
      setQueue((state) => {
        const tmp = [...state];
        const index = tmp.findIndex((o) => o._id === item._id);
        tmp.splice(index, 1);
        return tmp;
      });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [item._id, onCloseDeleteDialog, toast]);

  return (
    <Fragment>
      <HStack p="15px" _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }} borderWidth="1px" borderRadius="xl">
        <Center w="35px" h="35px" bg="blackAlpha.50" borderRadius="full">
          <Spinner size="xs" />
        </Center>
        <Box flex="1">
          <Text fontSize="xs" fontWeight="semibold">
            #{item.nid} • {item.title}
          </Text>
          <Text fontSize="xs">Esta captura está na fila aguardando o início do processamento.</Text>
        </Box>
        <Button size="sm" variant="outline" leftIcon={<Icon as={MdClose} />} isLoading={isLoadingDeleteData} onClick={onOpenDeleteDialog}>
          cancelar
        </Button>
      </HStack>
      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

const Failed = ({ item }) => {
  return (
    <HStack p="15px" bg="red.500" borderRadius="xl" color="white">
      <Center w="35px" h="35px" bg="red.600" borderRadius="full">
        <Icon as={MdError} />
      </Center>
      <Box flex="1">
        <Text fontSize="xs" fontWeight="semibold">
          #{item.nid} • {item.title}
        </Text>
        <Text fontSize="xs">Esta captura falhou durante o processamento. Por favor, verifique a informação.</Text>
      </Box>
      <Button
        size="sm"
        colorScheme="whiteAlpha"
        as={RouterLink}
        to={`/invoice-captures/details/${item._id}`}
        target="_blank"
        leftIcon={<Icon as={TbExternalLink} />}
      >
        visualizar
      </Button>
    </HStack>
  );
};

const Warned = ({ item }) => {
  return (
    <HStack p="15px" bg="yellow.200" borderRadius="xl" color="yellow.800">
      <Center w="35px" h="35px" bg="yellow.500" borderRadius="full" color="white">
        <Icon as={MdError} />
      </Center>
      <Box flex="1">
        <Text fontSize="xs" fontWeight="semibold">
          #{item.nid} • {item.title}
        </Text>
        <Text fontSize="xs">A captura de faturas foi finalizada com alguns alertas. Por favor, verifique a informação.</Text>
      </Box>
      <Button
        size="sm"
        colorScheme="yellow"
        as={RouterLink}
        to={`/invoice-captures/details/${item._id}`}
        target="_blank"
        leftIcon={<Icon as={TbExternalLink} />}
      >
        visualizar
      </Button>
    </HStack>
  );
};

const Finished = ({ item }) => {
  return (
    <HStack p="15px" bg="green.500" borderRadius="xl" color="white">
      <Center w="35px" h="35px" bg="green.600" borderRadius="full">
        <Icon as={MdCheck} />
      </Center>
      <Box flex="1">
        <Text fontSize="xs" fontWeight="semibold">
          #{item.nid} • {item.title}
        </Text>
        <Text fontSize="xs">Captura de faturas finalizada com sucesso.</Text>
      </Box>
      <Button
        size="sm"
        colorScheme="whiteAlpha"
        as={RouterLink}
        to={`/invoice-captures/details/${item._id}`}
        target="_blank"
        leftIcon={<Icon as={TbExternalLink} />}
      >
        visualizar
      </Button>
    </HStack>
  );
};

const Aborted = ({ item }) => {
  return (
    <HStack p="15px" bg="purple.500" borderRadius="xl" color="white">
      <Center w="35px" h="35px" bg="purple.600" borderRadius="full">
        <Icon as={MdBlock} />
      </Center>
      <Box flex="1">
        <Text fontSize="xs" fontWeight="semibold">
          #{item.nid} • {item.title}
        </Text>
        <Text fontSize="xs">A captura de faturas foi interrompida durante o processo.</Text>
      </Box>
      <Button
        size="sm"
        colorScheme="whiteAlpha"
        as={RouterLink}
        to={`/invoice-captures/details/${item._id}`}
        target="_blank"
        leftIcon={<Icon as={TbExternalLink} />}
      >
        visualizar
      </Button>
    </HStack>
  );
};

const Queue = () => {
  const { socket } = useContext(SocketContext);
  const { timestampData } = useContext(InvoiceCapturesContext);
  const query = useMemo(() => ({ status: { $in: ["waiting", "in_progress"] } }), []);
  const [data] = useFetchData(
    useMemo(() => ({ path: "/private/invoice-captures", params: { query, perPage: -1 } }), [query, timestampData])
  );
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    setQueue(data?.data || []);
  }, [data]);

  useEffect(() => {
    if (socket) {
      const onSave = (data) => {
        setQueue((state) => {
          const tmp = [...state];
          const index = tmp.findIndex((o) => o._id === data._id);
          if (index === -1) tmp.unshift(data);
          else if (tmp[index].status !== "aborted") tmp[index] = data;
          return tmp;
        });
      };
      socket.on("invoiceCaptures.save", onSave);
      return () => socket.removeListener("invoiceCaptures.save");
    }
  }, [socket]);

  return (
    <QueueContext.Provider value={{ queue, setQueue }}>
      <ScaleFade initialScale={0.9} in={_.size(queue) >= 1} hidden={_.size(queue) === 0}>
        <VStack alignItems="stretch" my={4}>
          {_.map(queue, (item) => {
            switch (item.status) {
              case "in_progress":
                return <InProgress key={item._id} {...{ item }} />;
              case "waiting":
                return <Waiting key={item._id} {...{ item }} />;
              case "failed":
                return <Failed key={item._id} {...{ item }} />;
              case "aborted":
                return <Aborted key={item._id} {...{ item }} />;
              default:
                if (item.invoicesCounters.rejected || item.electricityBalancesCounters.rejected)
                  return <Warned key={item._id} {...{ item }} />;
                return <Finished key={item._id} {...{ item }} />;
            }
          })}
        </VStack>
      </ScaleFade>
    </QueueContext.Provider>
  );
};

export default Queue;
