import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Heading, Highlight, HStack, Icon, LightMode, Text, VStack } from "@chakra-ui/react";
import { useDocumentTitle } from "hooks";
import { PrivateContext } from "pages/Private";
import { MdChevronRight } from "react-icons/md";
import { Content } from "../Container";
import _ from "lodash";

const imgs = [
  require("assets/slides/img-01.jpg"),
  require("assets/slides/img-02.jpg"),
  require("assets/slides/img-03.jpg"),
  require("assets/slides/img-04.jpg"),
  require("assets/slides/img-05.jpg"),
  require("assets/slides/img-06.jpg"),
  require("assets/slides/img-07.jpg"),
  require("assets/slides/img-08.jpg"),
  require("assets/slides/img-09.jpg"),
  require("assets/slides/img-10.jpg"),
  require("assets/slides/img-11.jpg"),
  require("assets/slides/img-12.jpg"),
];

export const Home = () => {
  useDocumentTitle("Página inicial");
  const { currentUser } = useContext(PrivateContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    imgs.forEach((img) => {
      const image = new Image();
      image.src = img;
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((state) => {
        if (state === 2) return 0;
        return state + 1;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Content>
      <Box position="relative" w="100%" h="100%" overflow="hidden" borderRadius="lg" role="group" mb="40px">
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bgImg={imgs[currentIndex]}
          bgSize="cover"
          transition="2000ms"
          _groupHover={{ transform: "scale(1.2)" }}
        />
        <VStack
          alignItems="flex-start"
          justifyContent="center"
          spacing="0"
          p={{ base: "30px", lg: "60px" }}
          w="100%"
          h="100%"
          minH="200px"
          bg="blackAlpha.600"
          color="white"
          position="relative"
          zIndex="1"
        >
          <Heading size="md">Olá, {currentUser.name}.</Heading>
          <Heading lineHeight="tall">
            <Highlight query="bem-vindo(a)" styles={{ px: "2", py: "1", rounded: "xl", bg: "main.500", color: "white" }}>
              Seja bem-vindo(a)
            </Highlight>
          </Heading>
          <Text fontWeight="semibold" maxW="2xl">
            O novo painel de administração foi desenvolvido para lhe ajudar a gerenciar suas demandas. Fique à vontade e bom trabalho!
          </Text>
          <Box h="20px" />
          <LightMode>
            <HStack>
              <Button as={RouterLink} to="/customers" colorScheme="whiteAlpha" rightIcon={<Icon as={MdChevronRight} />}>
                meus clientes
              </Button>
            </HStack>
          </LightMode>
        </VStack>
      </Box>
    </Content>
  );
};
