import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Button, HStack, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { StatusBadge } from "components";
import { currency } from "lib";
import { TbExternalLink } from "react-icons/tb";
import InvoicesDetailsContext from "../context";

const GroupedInvoices = () => {
  const { formData } = useContext(InvoicesDetailsContext);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(formData.groupChildren)} registros
        </Text>
      </HStack>

      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>NID</Th>
            <Th>Status</Th>
            <Th>Dt. emissão</Th>
            <Th>Dt. vencimento</Th>
            <Th>Valor</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(formData.groupChildren, (item) => (
            <Tr
              key={item._id}
              _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
              _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
            >
              <Td>
                <Button
                  size="sm"
                  variant="outline"
                  rightIcon={<Icon as={TbExternalLink} />}
                  as={RouterLink}
                  to={`/invoices/details/${item._id}`}
                  target="_blank"
                >
                  abrir fatura
                </Button>
              </Td>
              <Td>{item.nid}</Td>
              <Td>
                <StatusBadge schema="invoices" status={item.status} />
              </Td>
              <Td>{moment(item.issueDate).format("DD/MM/YYYY")}</Td>
              <Td>{moment(item.dueDate).format("DD/MM/YYYY")}</Td>
              <Td>{currency(item.amount)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default GroupedInvoices;
