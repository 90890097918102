import moment from "moment";
import { Link } from "@chakra-ui/react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { masks, translator, locale } from "lib";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "name",
    title: "Nome/Razão social",
    isVisible: true,
    render: ({ _id, name }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {name}
      </Link>
    ),
    exporter: ({ name }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return name;
      return "****";
    },
  },
  {
    accessor: "nickname",
    title: "Nome social/Fantasia",
    isVisible: false,
    render: ({ nickname }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return nickname || "-";
      return "****";
    },
    exporter: ({ nickname }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return nickname || "-";
      return "****";
    },
  },
  {
    accessor: "type",
    title: "Tipo",
    isVisible: false,
    formatter: ({ type }) => (type === "pf" ? "Pessoa física" : "Pessoa jurídica"),
  },
  {
    accessor: "document",
    title: "CPF/CNPJ",
    isVisible: true,
    render: ({ document }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.document(document);
      return "****";
    },
    exporter: ({ document }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return `'${document || "-"}`;
      return "****";
    },
  },
  {
    accessor: "rg",
    title: "RG",
    isVisible: false,
    render: ({ rg }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return rg || "-";
      return "****";
    },
    exporter: ({ rg }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return rg || "-";
      return "****";
    },
  },
  {
    accessor: "ie",
    title: "Inscrição estadual",
    isVisible: false,
    render: ({ ie }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return ie || "-";
      return "****";
    },
    exporter: ({ ie }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return ie || "-";
      return "****";
    },
  },
  {
    accessor: "earlyWarning",
    title: "Aviso prévio (dias)",
    isVisible: false,
    formatter: ({ earlyWarning }) => earlyWarning,
  },
  {
    accessor: "phone1",
    title: "Telefone 1",
    isVisible: true,
    render: ({ phone1 }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.phone(phone1);
      return "****";
    },
    exporter: ({ phone1 }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return masks.phone(phone1);
      return "****";
    },
  },
  {
    accessor: "phone2",
    title: "Telefone 2",
    isVisible: false,
    render: ({ phone2 }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.phone(phone2);
      return "****";
    },
    exporter: ({ phone2 }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return masks.phone(phone2);
      return "****";
    },
  },
  {
    accessor: "phone3",
    title: "Telefone 3",
    isVisible: false,
    render: ({ phone3 }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.phone(phone3);
      return "****";
    },
    exporter: ({ phone3 }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return masks.phone(phone3);
      return "****";
    },
  },
  {
    accessor: "email",
    title: "E-mail",
    isVisible: true,
    render: ({ email }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return email;
      return "****";
    },
    exporter: ({ email }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return email;
      return "****";
    },
  },
  {
    accessor: "address.zipCode",
    title: "CEP",
    isVisible: false,
    formatter: ({ address }) => masks.zipCode(address?.zipCode),
  },
  {
    accessor: "address.street",
    title: "Rua",
    isVisible: false,
    formatter: ({ address }) => address?.street,
  },
  {
    accessor: "address.number",
    title: "Número",
    isVisible: false,
    formatter: ({ address }) => address?.number,
  },
  {
    accessor: "address.complement",
    title: "Complemento",
    isVisible: false,
    formatter: ({ address }) => address?.complement,
  },
  {
    accessor: "address.neighborhood",
    title: "Bairro",
    isVisible: false,
    formatter: ({ address }) => address?.neighborhood,
  },
  {
    accessor: "address.city",
    title: "Cidade",
    isVisible: false,
    formatter: ({ address }) => address?.city,
  },
  {
    accessor: "address.state",
    title: "Estado",
    isVisible: false,
    formatter: ({ address }) => address?.state,
  },
  {
    accessor: "website",
    title: "Website",
    isVisible: false,
    formatter: ({ website }) => website,
  },
  {
    accessor: "gender",
    title: "Sexo",
    isVisible: false,
    formatter: ({ gender }) => translator(gender),
  },
  {
    accessor: "birth",
    title: "Nascimento",
    isVisible: false,
    formatter: ({ birth }) => moment(birth).format("DD/MM/YYYY"),
  },
  {
    accessor: "profession",
    title: "Profissão",
    isVisible: false,
    formatter: ({ profession }) => profession,
  },
  {
    accessor: "maritalStatus",
    title: "Estado civil",
    isVisible: false,
    formatter: ({ maritalStatus }) => maritalStatus,
  },
  {
    accessor: "naturalness",
    title: "Naturalidade",
    isVisible: false,
    formatter: ({ naturalness }) => naturalness,
  },
  {
    accessor: "exhibition",
    title: "Evento",
    isVisible: false,
    formatter: ({ exhibition }) => exhibition?.title,
  },
  {
    accessor: "indicatedBy",
    title: "Indicado por",
    isVisible: true,
    render: ({ indicatedBy }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData)
        return (
          <Link as={RouterLink} to={`edit/${indicatedBy?._id}`}>
            {indicatedBy?.name}
          </Link>
        );
      return "****";
    },
    exporter: ({ indicatedBy }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return indicatedBy?.name;
      return "****";
    },
  },
  {
    accessor: "indicationsCount",
    title: "Indicações",
    isVisible: true,
    isSortable: false,
    formatter: ({ indicationsCount }) => locale(indicationsCount, { precision: 0 }),
  },
  {
    accessor: "consumerUnitsCount",
    title: "Un. consumidoras",
    isVisible: true,
    formatter: ({ consumerUnitsCount }) => locale(consumerUnitsCount, { precision: 0 }),
  },
  {
    accessor: "contracts",
    title: "Contratos",
    isVisible: false,
    isSortable: false,
    formatter: ({ contracts }) => {
      if (_.size(contracts) === 0) return "-";
      const finded = _.find(contracts, (o) => !o.isClosed);
      return translator(finded?.status ?? "finished");
    },
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    formatter: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "consultant",
    title: "Consultor",
    isVisible: true,
    formatter: ({ consultant }) => consultant?.name,
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name,
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
