import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { currency, masks, percent, translator } from "lib";
import { StatusBadge } from "components";
import { TbExternalLink } from "react-icons/tb";
import { Button, HStack, Icon, Link, Text } from "@chakra-ui/react";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="charges" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "referenceNumber",
    title: "N° Documento",
    isVisible: true,
    formatter: ({ referenceNumber }) => referenceNumber,
  },
  {
    accessor: "ourNumber",
    title: "Nosso número",
    isVisible: true,
    formatter: ({ ourNumber }) => ourNumber,
  },
  {
    accessor: "customer.name",
    title: "Nome/Pagador",
    isVisible: true,
    isSortable: false,
    render: ({ customer }) => customer?.name || "-",
    exporter: ({ customer, isOverdue }, { isAllowedExportSensitiveData }) => {
      const value = customer?.name || "-";
      if (isAllowedExportSensitiveData.personalData) return value;
      if (isOverdue) return value;
      return "****";
    },
  },
  {
    accessor: "customer.document",
    title: "Documento/Pagador",
    isVisible: true,
    isSortable: false,
    render: ({ customer }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.document(customer?.document || "-");
      return "****";
    },
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return `'${customer?.document || "-"}`;
      return "****";
    },
  },
  {
    accessor: "messagedAt",
    title: "Dt. último envio",
    isVisible: true,
    formatter: ({ messagedAt }) => (messagedAt ? moment(messagedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "invoice.distributor.current.dueDate",
    title: "Vencimento CEMIG",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) =>
      invoice?.distributor?.current?.dueDate ? moment(invoice.distributor.current.dueDate).format("DD/MM/YYYY") : "-",
  },
  {
    accessor: "dueDate",
    title: "Vencimento Click",
    isVisible: true,
    formatter: ({ dueDate }) => (dueDate ? moment(dueDate).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "updatedAt",
    title: "Dt. último evento",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY [às] HH:mm"),
  },
  {
    accessor: "paidAt",
    title: "Pago em",
    isVisible: true,
    formatter: ({ paidAt }) => (paidAt ? moment(paidAt).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "canceledAt",
    title: "Cancelado em",
    isVisible: true,
    formatter: ({ canceledAt }) => (canceledAt ? moment(canceledAt).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "paymentMethod",
    title: "Método pgto",
    isVisible: true,
    formatter: ({ paymentMethod }) => paymentMethod,
  },
  {
    accessor: "amount",
    title: "Valor",
    isVisible: true,
    render: ({ amount }) => currency(amount),
    exporter: ({ amount }) => currency(amount, { compact: true }),
    renderFoot: ({ amount }) => currency(amount),
  },
  {
    accessor: "amountPaid",
    title: "Valor pago",
    isVisible: true,
    render: ({ amountPaid }) => currency(amountPaid),
    exporter: ({ amountPaid }) => currency(amountPaid, { compact: true }),
    renderFoot: ({ amountPaid }) => currency(amountPaid),
  },
  {
    accessor: "invoice.nid",
    title: "Fatura/NID",
    isVisible: true,
    isSortable: false,
    render: ({ invoice }) => (
      <Button
        size="xs"
        variant="outline"
        rightIcon={<Icon as={TbExternalLink} />}
        as={RouterLink}
        to={`/invoices/details/${invoice._id}`}
        target="_blank"
      >
        <HStack>
          <StatusBadge schema="invoices" status={invoice.status} />
          <Text>#{invoice.nid}</Text>
        </HStack>
      </Button>
    ),
    exporter: ({ invoice }) => invoice.nid,
  },
  {
    accessor: "invoice.referenceDate",
    title: "Fatura/Ref.",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) => (invoice?.referenceDate ? moment(invoice.referenceDate).format("MM/YYYY") : "-"),
  },
  {
    accessor: "invoice.consumerUnit.cemigInstallationNumber",
    title: "Num. instalação",
    isVisible: true,
    isSortable: false,
    render: ({ customer, invoice, isOverdue }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.cemigInstallationNumber)
        return (
          <Link as={RouterLink} to={`/customers/edit/${customer._id}/#consumer-units`}>
            {invoice?.consumerUnit?.cemigInstallationNumber}
          </Link>
        );
      if (isOverdue) return masks.obfuscate(invoice?.consumerUnit?.cemigInstallationNumber);
      return "****";
    },
    exporter: ({ invoice, isOverdue }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.cemigInstallationNumber) return invoice?.consumerUnit?.cemigInstallationNumber;
      if (isOverdue) return masks.obfuscate(invoice?.consumerUnit?.cemigInstallationNumber);
      return "****";
    },
  },
  {
    accessor: "customer.primaryBillingContact.name",
    title: "Nome/Faturamento",
    isVisible: false,
    isSortable: false,
    render: ({ customer, isOverdue }, { isAllowedReadSensitiveData }) => {
      const value = customer?.primaryBillingContact?.name || "-";
      if (isAllowedReadSensitiveData.personalData) return value;
      if (isOverdue) return value;
      return "****";
    },
    exporter: ({ customer, isOverdue }, { isAllowedExportSensitiveData }) => {
      const value = customer?.primaryBillingContact?.name || "-";
      if (isAllowedExportSensitiveData.personalData) return value;
      if (isOverdue) return value;
      return "****";
    },
  },
  {
    accessor: "customer.primaryBillingContact.email",
    title: "E-mail/Faturamento",
    isVisible: true,
    isSortable: false,
    render: ({ customer, isOverdue }, { isAllowedReadSensitiveData }) => {
      const value = customer?.primaryBillingContact?.email || "-";
      if (isAllowedReadSensitiveData.personalData) return value;
      if (isOverdue) return masks.obfuscateEmail(value);
      return "****";
    },
    exporter: ({ customer, isOverdue }, { isAllowedExportSensitiveData }) => {
      const value = customer?.primaryBillingContact?.email || "-";
      if (isAllowedExportSensitiveData.personalData) return value;
      if (isOverdue) return masks.obfuscateEmail(value);
      return "****";
    },
  },
  {
    accessor: "customer.primaryBillingContact.phone",
    title: "Telefone/Faturamento",
    isVisible: true,
    isSortable: false,
    render: ({ customer, isOverdue }, { isAllowedReadSensitiveData }) => {
      const value = masks.phone(customer?.primaryBillingContact?.phone) || "-";
      if (isAllowedReadSensitiveData.personalData) return value;
      if (isOverdue) return value;
      return "****";
    },
    exporter: ({ customer, isOverdue }, { isAllowedExportSensitiveData }) => {
      const value = masks.phone(customer?.primaryBillingContact?.phone) || "-";
      if (isAllowedExportSensitiveData.personalData) return value;
      if (isOverdue) return value;
      return "****";
    },
  },
  {
    accessor: "invoice.isUnified",
    title: "Modalidade de fatura",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) => (invoice.isUnified ? "Fatura unificada" : "Fatura separada"),
  },
  {
    accessor: "invoice.isFirstInvoiceIssue",
    title: "Primeira fatura?",
    isVisible: true,
    formatter: ({ invoice }) => (invoice?.isFirstInvoiceIssue ? "Sim" : "Não"),
  },
  {
    accessor: "invoice.sendingMethod",
    title: "Mét. receb. fatura",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) => translator(invoice?.sendingMethod || "-"),
  },
  {
    accessor: "penaltyAmount",
    title: "Multa",
    isVisible: false,
    formatter: ({ penaltyAmount }) => currency(penaltyAmount),
  },
  {
    accessor: "arrearsAmount",
    title: "R$ Juros",
    isVisible: false,
    formatter: ({ arrearsAmount }) => currency(arrearsAmount),
  },
  {
    accessor: "arrearsPercentage",
    title: "% Juros",
    isVisible: false,
    formatter: ({ arrearsPercentage }) => percent(arrearsPercentage),
  },
  {
    accessor: "monetaryCorrectionAmount",
    title: "R$ Correção IPCA",
    isVisible: false,
    formatter: ({ monetaryCorrectionAmount }) => currency(monetaryCorrectionAmount),
  },
  {
    accessor: "monetaryCorrectionPercentage",
    title: "% Correção IPCA",
    isVisible: false,
    formatter: ({ monetaryCorrectionPercentage }) => percent(monetaryCorrectionPercentage),
  },
  {
    accessor: "customer.consultant.name",
    title: "Consultor",
    isVisible: true,
    isSortable: false,
    formatter: ({ customer }) => customer?.consultant?.name || "-",
  },

  {
    accessor: "invoice.consumerUnit.substatus",
    title: "Substatus/UC",
    isVisible: true,
    isSortable: false,
    render: ({ invoice }) => <StatusBadge {...invoice?.consumerUnit?.substatus} />,
    exporter: ({ invoice }) => invoice?.consumerUnit?.substatus?.title,
  },
  {
    accessor: "invoice.consumerUnit.paymentProfile",
    title: "Perfil de pagamento",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) => invoice?.consumerUnit?.paymentProfile?.title || "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name || "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY [às] HH:mm"),
  },
];

export default defaultColumns;
