import React, { useMemo, useRef, useState, useCallback } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { MdMoreHoriz } from "react-icons/md";
import { FiClipboard, FiTrash } from "react-icons/fi";
import {
  Breadcrumb,
  CheckboxBody,
  CheckboxHeader,
  CheckboxProvider,
  ExportCsvStream,
  Paginator,
  PermissionedContainer,
  Portal,
  TableDrawer,
  TableEmpty,
} from "components";
import { api } from "lib";
import { useFetchData, useCacheState, useClipboard, useCustomToast, useDocumentTitle, useTable, useNewTabNavigate } from "hooks";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import defaultColumns from "./defaultColumns";
import { TbFileExport, TbFileInvoice, TbHomeBolt } from "react-icons/tb";
import Filters from "./filters";

export const PowerManagementList = () => {
  useDocumentTitle("Gestão de energia");
  const location = useLocation();
  const newTabNavigate = useNewTabNavigate();
  const [query, setQuery] = useState();
  const [sort, setSort] = useCacheState(useMemo(() => ({ key: "PowerManagementList.sort", defaultValue: { referenceDate: -1 } }), []));
  const [page, setPage] = useCacheState(useMemo(() => ({ key: "PowerManagementList.page", defaultValue: 0 }), []));
  const [perPage, setPerPage] = useCacheState(useMemo(() => ({ key: "PowerManagementList.perPage", defaultValue: 100 }), []));
  const [search, setSearch] = useCacheState(useMemo(() => ({ key: "PowerManagementList.search", defaultValue: "" }), []));
  const [data, isLoadingData, refreshData, errorData, timestampData, fetchAllPages] = useFetchData(
    useMemo(
      () => ({
        path: "/private/power-management",
        params: { query, sort, page, perPage, search },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage, search]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 3 }), [location.pathname])
  );
  const [checkeds, setCheckeds] = useState([]);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const [isLoadingNavigate, setIsLoadingNavigate] = useState({});
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const toast = useCustomToast();
  const tableDrawerRef = useRef();
  const copyToClipboard = useClipboard();

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/private/power-management", { data: _.map(checkeds, "_id") });
      setCheckeds([]);
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [checkeds, onCloseDeleteDialog, toast, refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  const handleNavigateToCustomer = useCallback(async (item) => {
    try {
      setIsLoadingNavigate((state) => ({ ...state, [item._id]: true }));
      const query = { consumerUnit: { cemigInstallationNumber: item.installationNumber } };
      const response = await api.post("/private/consumer-units", { query });
      const [consumerUnit] = response.data;
      window.open(`/customers/edit/${consumerUnit.customer._id}#consumer-units`);
    } finally {
      setIsLoadingNavigate((state) => ({ ...state, [item._id]: false }));
    }
  }, []);

  const handleExportData = useCallback(
    (visibleColumns) => api.post("/private/power-management", { query, sort, isExport: true, visibleColumns }),
    [query, sort]
  );

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "gestão" }, { to: "/power-management", label: "gestão de energia" }]} />
            <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="-20px">
              <HStack>
                <PermissionedContainer required={["powerManagement.export"]}>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="powerManagement.export">
                            <MenuItem onClick={onOpenExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar gestão de energia</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              </HStack>
            </SlideFade>
          </HStack>
          <Heading my="15px" size="md">
            Gestão de energia
          </Heading>
          <Filters
            onSearch={setSearch}
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <CheckboxProvider key={timestampData} values={checkeds} onChange={setCheckeds}>
            <Table size="sm" whiteSpace="nowrap">
              <Thead>
                <Tr>
                  <Th>
                    <CheckboxHeader />
                  </Th>
                  {cells.map(({ accessor, title }) => (
                    <Th key={accessor}>{title}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {_.map(data?.data, (item) => (
                  <Tr
                    key={item._id}
                    _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                    _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                  >
                    <Td>
                      <HStack>
                        <CheckboxBody value={item} />
                        <Box>
                          <Menu placement="right-start">
                            <MenuButton
                              as={IconButton}
                              size="xs"
                              colorScheme="main"
                              icon={<Icon as={MdMoreHoriz} />}
                              isLoading={isLoadingNavigate[item._id]}
                            />
                            <MenuList>
                              <MenuGroup title={item.name} pb="5px">
                                <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                  copiar código
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem icon={<Icon as={TbHomeBolt} />} onClick={() => handleNavigateToCustomer(item)}>
                                  abrir unidade consumidora
                                </MenuItem>
                                <MenuItem
                                  icon={<Icon as={TbFileInvoice} />}
                                  onClick={() => newTabNavigate("/invoices", { installationNumber: item.installationNumber })}
                                >
                                  abrir histórico de faturas
                                </MenuItem>
                                <PermissionedContainer required="powerManagement.delete">
                                  <MenuDivider />
                                  <MenuItem
                                    icon={<Icon as={FiTrash} />}
                                    onClick={() => {
                                      setCheckeds([item]);
                                      onOpenDeleteDialog();
                                    }}
                                  >
                                    excluir
                                  </MenuItem>
                                </PermissionedContainer>
                              </MenuGroup>
                            </MenuList>
                          </Menu>
                        </Box>
                      </HStack>
                    </Td>
                    {cells.map(({ accessor, formatter, render }) => (
                      <Td key={accessor}>{formatter?.(item) ?? render?.(item, { handleNavigateToCustomer, isLoadingNavigate })}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {isLoadingData && (
              <Center p="30px">
                <Spinner />
              </Center>
            )}
            <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
          </CheckboxProvider>
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={checkeds.length} hidden={checkeds.length === 0} offsetY="20px">
        <HStack p="20px" justify="space-between">
          <Text>{checkeds.length} selecionados</Text>
          <Button size="sm" variant="outline" leftIcon={<Icon as={FiTrash} />} onClick={onOpenDeleteDialog}>
            excluir cadastros
          </Button>
        </HStack>
      </SlideFade>

      <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsvStream
        filename="gestao_de_energia"
        onStreamData={handleExportData}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
      />

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
