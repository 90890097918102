import React, { useMemo, useRef, useCallback, useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Breadcrumb, ExportCsvStream, Paginator, PermissionedContainer, Portal, TableDrawer, TableEmpty } from "components";
import { useFetchData, useCacheState, useClipboard, useDocumentTitle, useQueryParams, useTable } from "hooks";
import { api, EventEmitter } from "lib";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { TbFileExport } from "react-icons/tb";
import { MdMoreHoriz } from "react-icons/md";
import { BiListUl } from "react-icons/bi";
import { FiClipboard } from "react-icons/fi";
import defaultColumns from "./defaultColumns";
import Filters from "./filters";

export const AlertsList = () => {
  useDocumentTitle("Central de alertas");
  const location = useLocation();
  const { setQueryParams } = useQueryParams();
  const [query, setQuery] = useState();
  const [sort, setSort] = useCacheState(useMemo(() => ({ key: "AlertsList.sort", defaultValue: { createdAt: -1 } }), []));
  const [page, setPage] = useCacheState(useMemo(() => ({ key: "AlertsList.page", defaultValue: 0 }), []));
  const [perPage, setPerPage] = useCacheState(useMemo(() => ({ key: "AlertsList.perPage", defaultValue: 100 }), []));
  const [data, isLoadingData, refreshData, errorData, timestampData, fetchAllPages] = useFetchData(
    useMemo(
      () => ({
        path: "/private/alerts",
        params: { query, sort, page, perPage },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 3 }), [location.pathname])
  );
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const copyToClipboard = useClipboard();
  const tableDrawerRef = useRef();

  useEffect(() => {
    const listener = EventEmitter.addListener("alerts.refresh", refreshData);
    return () => listener.remove();
  }, [refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  const handleExportData = useCallback(
    (visibleColumns) => api.post("/private/alerts", { query, sort, isExport: true, visibleColumns }),
    [query, sort]
  );

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "índice" }, { to: "/alerts", label: "central de alertas" }]} />
            <SlideFade in={true} offsetY="-20px">
              <HStack>
                <PermissionedContainer required={["alerts.export"]}>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="alerts.export">
                            <MenuItem onClick={onOpenExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar alertas</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              </HStack>
            </SlideFade>
          </HStack>

          <Heading my="15px" size="md">
            Central de alertas
          </Heading>
          <Filters
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <Table size="sm" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th>#</Th>
                {cells.map(({ accessor, title }) => (
                  <Th key={accessor}>{title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {_.map(data?.data, (item) => (
                <Tr
                  key={item._id}
                  cursor="pointer"
                  _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                  _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                  onDoubleClick={() => setQueryParams((params) => ({ ...params, alert_id: item._id }), { replace: true })}
                >
                  <Td>
                    <HStack>
                      <Box>
                        <Menu placement="right-start">
                          <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                          <MenuList>
                            <MenuGroup title={item.referenceNumber} pb="5px">
                              <VStack spacing={2} divider={<StackDivider />}>
                                <Fragment>
                                  <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                    copiar código
                                  </MenuItem>
                                  <PermissionedContainer required="alerts.read">
                                    <MenuItem
                                      icon={<Icon as={BiListUl} />}
                                      onClick={() =>
                                        setQueryParams((params) => ({ ...params, alert_id: item._id }), {
                                          replace: true,
                                        })
                                      }
                                    >
                                      detalhes
                                    </MenuItem>
                                  </PermissionedContainer>
                                </Fragment>
                              </VStack>
                            </MenuGroup>
                          </MenuList>
                        </Menu>
                      </Box>
                    </HStack>
                  </Td>
                  {cells.map(({ accessor, formatter, render }) => (
                    <Td key={accessor}>{formatter?.(item) ?? render?.(item, { setQueryParams })}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
            <Tfoot _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }} fontWeight="bold">
              <Tr>
                <Td></Td>
                {cells.map(({ accessor, renderFoot }) => (
                  <Td key={accessor}>{renderFoot?.(data?.footer ?? {})}</Td>
                ))}
              </Tr>
            </Tfoot>
          </Table>
          {isLoadingData && (
            <Center p="30px">
              <Spinner />
            </Center>
          )}
          <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsvStream
        filename="monitoramentos_cemig"
        onStreamData={handleExportData}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
      />
    </>
  );
};
