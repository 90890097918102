import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, translator, setOneOrMany } from "lib";
import { AsyncSelect, SyncSelect, RangeDateInput, InputCurrency, RangeInput } from "components";
import { messageTopics, statuses } from "consts";
import { useCacheState, useFetchData, useStickyState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

const channels = [{ value: "email" }, { value: "whatsapp" }];
const topics = Object.entries(messageTopics).map(([value, label]) => ({ value, label }));

let loadUsersTimeout = {};
let loadCustomersTimeout;

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? {
          status: statuses.messages.filter((o) => _.includes(["bounce", "complaint", "failed", "error"], o.value)),
          isNewest: "yes",
        },
        useCached: _.isObject(location.state) === false,
        _v: 4,
        processor: (data) => ({
          ...data,
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
          archivedAtStart: data.archivedAtStart && moment(data.archivedAtStart).toDate(),
          archivedAtEnd: data.archivedAtEnd && moment(data.archivedAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      [location.pathname]
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );
  const [consumerUnitStatuses, isLoadingConsumerUnitStatuses, refreshConsumerUnitStatuses] = useFetchData(
    useMemo(
      () => ({
        path: "/private/statuses",
        params: {
          query: { moduleName: "ConsumerUnit", isActive: true },
          sort: { ordination: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
      }),
      []
    )
  );

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);

    if (query.verificationStatus?.length) _.set(response, "message.verificationStatus.$in", _.map(query.verificationStatus, "value"));
    if (query.status?.length) _.set(response, "message.status.$in", _.map(query.status, "value"));
    if (query.channel?.length) _.set(response, "message.channel.$in", _.map(query.channel, "value"));
    if (query.topic?.length) _.set(response, "message.topic.$in", _.map(query.topic, "value"));
    setOneOrMany("message.contactTarget", query.contactTarget, response);
    if (query.createdAtStart) _.set(response, "message.createdAt.$gte", ["@ISODate", query.createdAtStart]);
    if (query.createdAtEnd) _.set(response, "message.createdAt.$lte", ["@ISODate", query.createdAtEnd]);
    if (query.archivedAtStart) _.set(response, "message.archivedAt.$gte", ["@ISODate", query.archivedAtStart]);
    if (query.archivedAtEnd) _.set(response, "message.archivedAt.$lte", ["@ISODate", query.archivedAtEnd]);
    if (query.archivedBy?.length) _.set(response, "message.archivedBy.$in", toObjectId(query.archivedBy));
    if (query.isNewest?.length) _.set(response, "message.isNewest", true);

    if (query.customerId?.length) _.set(response, "customer._id.$in", toObjectId(query.customerId));
    if (query.customerConsultant?.length) _.set(response, "customer.consultant.$in", toObjectId(query.customerConsultant));

    setOneOrMany("consumerUnit.cemigInstallationNumber", query.consumerUnitInstallationNumber, response);
    const instNum = _.get(response, "consumerUnit.cemigInstallationNumber");
    if (_.isString(instNum)) _.set(response, "consumerUnit.cemigInstallationNumber", { $regex: instNum.concat("$") });

    if (query.consumerUnitStatus?.length) _.set(response, "consumerUnit.status.$in", toObjectId(query.consumerUnitStatus));
    if (query.consumerUnitCheckedElectricityAverageSpentInKwhStart)
      _.set(response, "consumerUnit.checkedElectricityAverageSpentInKwh.$gte", query.consumerUnitCheckedElectricityAverageSpentInKwhStart);
    if (query.consumerUnitCheckedElectricityAverageSpentInKwhEnd)
      _.set(response, "consumerUnit.checkedElectricityAverageSpentInKwh.$lte", query.consumerUnitCheckedElectricityAverageSpentInKwhEnd);

    setOneOrMany("invoice.referenceCode", query.invoiceReferenceCode, response);
    setOneOrMany("invoice.nid", query.invoiceNid, response, (v) => parseInt(v));
    if (query.invoiceStatus?.length) _.set(response, "invoice.status.$in", _.map(query.invoiceStatus, "value"));
    if (query.invoiceIsUnified?.length) _.set(response, "invoice.isUnified", query.invoiceIsUnified === "yes");
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadUsers = useCallback((key, search, cb, perPage = 20) => {
    clearTimeout(loadUsersTimeout[key]);
    loadUsersTimeout[key] = setTimeout(async () => {
      const response = await api.post("/private/users", { search, perPage, sort: { name: 1 }, isAutocomplete: true, withSystem: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <HStack spacing={4} wrap={{ base: "wrap", lg: "nowrap" }} direction={{ base: "column", lg: "row" }} mb={8}>
        <HStack w={{ base: "100%", lg: "auto" }}>
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme={isFiltering ? "main" : "gray"}
            variant="outline"
            rightIcon={<Icon as={HiOutlineFilter} />}
            onClick={() => setIsOpen((state) => !state)}
          >
            filtros
          </Button>
          {isFiltering && (
            <Button flex={{ base: "1", lg: "none" }} variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
              limpar filtros
            </Button>
          )}
        </HStack>
        <Box minW={{ base: "full", lg: "xs" }}>
          <Input
            placeholder="N° de instalação"
            value={formData.consumerUnitInstallationNumber ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, consumerUnitInstallationNumber: target.value }))}
          />
        </Box>
        <Box minW={{ base: "full", lg: "xs" }}>
          <AsyncSelect
            isMulti
            value={formData.customerId ?? []}
            defaultOptions
            loadOptions={handleLoadCustomers}
            placeholder="Cliente"
            onChange={(customerId) => setFormData((state) => ({ ...state, customerId }))}
            getOptionValue={({ _id }) => _id}
            formatOptionLabel={({ name }) => name}
            isClearable={true}
          />
        </Box>
        <HStack flex="1" w={{ base: "100%", lg: "auto" }} justifyContent="flex-end">
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme="main"
            rightIcon={<Icon as={MdSearch} />}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            aplicar
          </Button>
          <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
          <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
        </HStack>
      </HStack>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status da verificação
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.verificationStatus ?? []}
                  placeholder="Selecione"
                  options={statuses.messagesVerification}
                  onChange={(verificationStatus) => setFormData((state) => ({ ...state, verificationStatus }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status do envio
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.messages}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status da fatura
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.invoiceStatus ?? []}
                  placeholder="Selecione"
                  options={statuses.invoices}
                  onChange={(invoiceStatus) => setFormData((state) => ({ ...state, invoiceStatus }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status da UC
                </FormLabel>
                <HStack>
                  <SyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    withSelectAll
                    value={formData.consumerUnitStatus ?? []}
                    options={consumerUnitStatuses?.data || []}
                    placeholder="Selecione"
                    onChange={(consumerUnitStatus) => setFormData((state) => ({ ...state, consumerUnitStatus }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ colorScheme, label, title }) => (
                      <HStack>
                        <Box bg={`${colorScheme}.500`} w="10px" h="10px" borderRadius="full" />
                        <Text>{label || title}</Text>
                      </HStack>
                    )}
                    isClearable={true}
                  />
                  <IconButton
                    size="sm"
                    variant="outline"
                    icon={<Icon as={MdRefresh} />}
                    isLoading={isLoadingConsumerUnitStatuses}
                    onClick={refreshConsumerUnitStatuses}
                  />
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Canal
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.channel ?? []}
                  placeholder="Selecione"
                  options={channels}
                  onChange={(channel) => setFormData((state) => ({ ...state, channel }))}
                  formatOptionLabel={({ value }) => translator(value)}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tópico
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.topic ?? []}
                  placeholder="Selecione"
                  options={topics}
                  onChange={(topic) => setFormData((state) => ({ ...state, topic }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Destinatário
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.contactTarget ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, contactTarget: target.value }))}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID/Fatura
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.invoiceNid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, invoiceNid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Consultor
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customerConsultant ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("customerConsultant", search, cb, -1)}
                  placeholder="Selecione"
                  onChange={(customerConsultant) => setFormData((state) => ({ ...state, customerConsultant }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cod. referência
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.invoiceReferenceCode ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, invoiceReferenceCode: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Média de consumo corrigida
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  precision="0"
                  defaultStartValue={formData.consumerUnitCheckedElectricityAverageSpentInKwhStart}
                  defaultEndValue={formData.consumerUnitCheckedElectricityAverageSpentInKwhEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({
                      ...state,
                      consumerUnitCheckedElectricityAverageSpentInKwhStart: startValue,
                      consumerUnitCheckedElectricityAverageSpentInKwhEnd: endValue,
                    }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">kWh</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Modalidade de fatura
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.invoiceIsUnified ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, invoiceIsUnified: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Fatura unificada</option>
                  <option value="no">Fatura separada</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criada em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.createdAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.createdAtStart}
                    defaultEndDate={formData.createdAtEnd}
                    onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                  />
                  {formData.createdAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStart: null,
                          createdAtEnd: null,
                          createdAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Arquivada por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.archivedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("archivedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(archivedBy) => setFormData((state) => ({ ...state, archivedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Arquivado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.archivedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.archivedAtStart}
                    defaultEndDate={formData.archivedAtEnd}
                    onChange={(archivedAtStart, archivedAtEnd) => setFormData((state) => ({ ...state, archivedAtStart, archivedAtEnd }))}
                  />
                  {formData.archivedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          archivedAtStart: null,
                          archivedAtEnd: null,
                          archivedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Apenas a última mensagem?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isNewest ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isNewest: target.value }))}
                >
                  <option value="yes">Sim</option>
                  <option value="">Não</option>
                </Select>
              </FormControl>
            </GridItem>
          </Grid>

          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
