import React, { useContext, useCallback, Fragment } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  Box,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { api, currency, percent, invoiceHelpers } from "lib";
import { AddressText, InputCurrency, ModalSelect, PermissionedContainer } from "components";
import { MdAttachMoney, MdOutlineClose, MdOutlineModeEdit, MdOutlineSolarPower } from "react-icons/md";
import { PiPlant } from "react-icons/pi";
import { TbExternalLink, TbHomeBolt, TbRosetteDiscount, TbUserBolt } from "react-icons/tb";
import InvoicesDetailsContext from "../context";
import Items from "./items";
import Distributor from "./distributor";
import ElectricityBalance from "./electricityBalance";

export const Editable = ({ value, EditableComponent, buttonProps = { size: "sm" }, isEditable }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return isOpen ? (
    <HStack spacing={1}>
      {EditableComponent}
      <IconButton icon={<Icon as={MdOutlineClose} />} onClick={onClose} {...buttonProps} />
    </HStack>
  ) : (
    <HStack justifyContent="center">
      {value}
      {isEditable && <IconButton icon={<Icon as={MdOutlineModeEdit} />} onClick={onOpen} {...buttonProps} />}
    </HStack>
  );
};

const Trader = () => {
  const { formData, setFormData, formErrors, isEditable } = useContext(InvoicesDetailsContext);

  const handleLoadConsumerUnits = useCallback(async (search) => {
    const params = { search, perPage: 20, isAutocomplete: true };
    const response = await api.post(`/private/consumer-units`, params);
    return response?.data || [];
  }, []);

  const handlePowerPlants = useCallback(async (search) => {
    const response = await api.post("/private/power-plants", { search, perPage: 20, isAutocomplete: true });
    return response?.data || [];
  }, []);

  const handleLoadPromotions = useCallback(async (search) => {
    const response = await api.post("/private/promotions", { search, perPage: 20, isAutocomplete: true });
    return response?.data || [];
  }, []);

  const handleChangeConsumerUnit = useCallback(
    (consumerUnit) =>
      setFormData((state) => {
        const tmp = {
          ...state,
          customer: consumerUnit.customer,
          consumerUnit,
          agreedDiscount: {
            ...state.agreedDiscount,
            percentage: consumerUnit.agreedDiscount,
          },
          discountPercentage: consumerUnit.agreedDiscount,
          tariffGroup: consumerUnit.tariffGroup,
          powerPlant: consumerUnit.powerPlant,
        };
        invoiceHelpers.reprocessReferenceCode(tmp);
        invoiceHelpers.reprocessEletricPower(tmp);
        invoiceHelpers.reprocessAmounts(tmp);
        return tmp;
      }),
    []
  );

  const handleChangeReferenceDate = useCallback(
    ({ target }) =>
      setFormData((state) => {
        const tmp = { ...state, referenceDate: target.value.toUpperCase() };
        invoiceHelpers.reprocessReferenceCode(tmp);
        return tmp;
      }),

    []
  );

  const handleChangePromotion = useCallback((promotion) => {
    setFormData((state) => {
      const tmp = { ...state };
      if (promotion) {
        tmp.promotionDiscount = {
          promotion,
          percentage: promotion.discountPercentage,
          usageInMonths: 1,
          durationInMonths: promotion.discountDurationInMonths,
        };
        tmp.discountPercentage = promotion.discountPercentage;
      } else {
        tmp.promotionDiscount = null;
        tmp.discountPercentage = tmp.agreedDiscount.percentage;
      }
      invoiceHelpers.reprocessEletricPower(tmp);
      invoiceHelpers.reprocessAmounts(tmp);
      return tmp;
    });
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={2}>
        <GridItem colSpan={5} borderWidth="1px" borderRadius="lg" p="20px">
          {formData.customer ? (
            <Fragment>
              <HStack>
                <Heading size="sm">{formData.customer?.name || "-"}</Heading>
                <PermissionedContainer required="customers.read">
                  <IconButton
                    size="xs"
                    variant="outline"
                    as={RouterLink}
                    icon={<Icon as={TbExternalLink} />}
                    to={`/customers/edit/${formData.customer?._id}`}
                    target="_blank"
                  />
                </PermissionedContainer>
              </HStack>
              <Text fontSize="xs">
                {formData.consumerUnit?.address?.street}, {formData.consumerUnit?.address?.number} -{" "}
                {formData.consumerUnit?.address?.complement}
              </Text>
              <Text fontSize="xs">
                {formData.consumerUnit?.address?.neighborhood} - {formData.consumerUnit?.address?.city},{" "}
                {formData.consumerUnit?.address?.state}
              </Text>
              <Text fontSize="xs">CEP {formData.consumerUnit?.address?.zipCode}</Text>
            </Fragment>
          ) : (
            <VStack spacing={0} alignItems="center">
              <Icon as={TbUserBolt} boxSize="20px" mb={2} />
              <Heading size="sm">Cliente não informado</Heading>
              <Text fontSize="xs">Esta fatura ainda não possui um cliente relacionado.</Text>
            </VStack>
          )}
        </GridItem>
        <GridItem colSpan={4} as={HStack} spacing={4} borderWidth="1px" borderRadius="lg" p="20px">
          <Center w="60px" h="60px" borderRadius="full" bg="gray.100">
            <Icon as={TbHomeBolt} boxSize="25px" />
          </Center>
          <Box flex="1">
            <Text fontSize="xs">Unidade consumidora</Text>
            <Heading size="sm">{formData.consumerUnit?.cemigInstallationNumber || "-"}</Heading>
            <Text fontSize="sm">
              {formData.consumerUnit?.customerClass?.toUpperCase()} {formData.consumerUnit?.installationType?.toUpperCase()}
            </Text>
            <Text fontSize="xs" fontWeight="semibold">
              Créd. Click disponível• {currency(formData.consumerUnit?.traderAvailableCreditBalance || 0)}
            </Text>
            <Text fontSize="xs" fontWeight="semibold">
              Créd. CEMIG disponível • {currency(formData.consumerUnit?.distributorAvailableCreditBalance || 0)}
            </Text>
          </Box>
          <HStack>
            {isEditable && (
              <ModalSelect
                loadOptions={handleLoadConsumerUnits}
                onChange={handleChangeConsumerUnit}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ cemigInstallationNumber, address, customer }) => (
                  <Box>
                    <Heading size="sm">{cemigInstallationNumber}</Heading>
                    <Text fontSize="sm">{customer.name}</Text>
                    <AddressText address={address} />
                  </Box>
                )}
              />
            )}
            <ElectricityBalance />
            <PermissionedContainer required="customers.read">
              <IconButton
                variant="outline"
                as={RouterLink}
                icon={<Icon as={TbExternalLink} />}
                to={`/customers/edit/${formData.customer?._id}#consumer-units`}
                target="_blank"
              />
            </PermissionedContainer>
          </HStack>
        </GridItem>
        <GridItem colSpan={3} as={HStack} spacing={4} justifyContent="flex-start" borderWidth="1px" borderRadius="lg" p="20px">
          <Center w="60px" h="60px" borderRadius="full" bg="gray.100">
            <Icon as={MdOutlineSolarPower} boxSize="25px" />
          </Center>
          <Box flex="1">
            <Text fontSize="sm">Usina</Text>
            <Text fontWeight="semibold">{formData.powerPlant?.name || "-"}</Text>
          </Box>
          {isEditable && (
            <ModalSelect
              loadOptions={handlePowerPlants}
              onChange={(powerPlant) => setFormData((state) => ({ ...state, powerPlant }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ name }) => name}
            />
          )}
        </GridItem>

        <GridItem colSpan={2} as={VStack} spacing={0} justifyContent="center" borderWidth="1px" borderRadius="lg" p="20px">
          <FormControl as={VStack} spacing={0} justifyContent="center" isInvalid={formErrors.referenceDate}>
            <Text fontSize="sm">Referência</Text>
            <Editable
              value={
                <Heading size="md" whiteSpace="nowrap">
                  {formData.referenceDate}
                </Heading>
              }
              EditableComponent={
                <Input size="sm" as={InputMask} mask="aaa/9999" value={formData.referenceDate} onChange={handleChangeReferenceDate} />
              }
              isEditable={isEditable}
            />
            <FormErrorMessage>{formErrors.referenceDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2} as={VStack} spacing={0} justifyContent="center" borderWidth="1px" borderRadius="lg" p="20px">
          <FormControl as={VStack} spacing={0} justifyContent="center" isInvalid={formErrors.dueDate}>
            <Text fontSize="sm">Vencimento</Text>
            <Editable
              value={
                <Heading size="md" whiteSpace="nowrap">
                  {formData.dueDate}
                </Heading>
              }
              EditableComponent={
                <Input
                  size="sm"
                  as={InputMask}
                  mask="99/99/9999"
                  value={formData.dueDate}
                  onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: target.value }))}
                />
              }
              isEditable={isEditable}
            />
            <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2} as={VStack} spacing={0} justifyContent="center" borderWidth="1px" borderRadius="lg" p="20px">
          <Text fontSize="sm">Consumo</Text>
          <Editable
            value={
              <Heading size="md" whiteSpace="nowrap">
                {formData.consumedPowerQttInKwh?.toLocaleString() || 0} kWh
              </Heading>
            }
            EditableComponent={
              <InputGroup size="sm">
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.consumedPowerQttInKwh}
                  onChange={(consumedPowerQttInKwh) => setFormData((state) => ({ ...state, consumedPowerQttInKwh }))}
                />
                <InputRightElement>kWh</InputRightElement>
              </InputGroup>
            }
            isEditable={isEditable}
          />
        </GridItem>
        <GridItem colSpan={3} as={HStack} spacing={4} justifyContent="flex-start" borderWidth="1px" borderRadius="lg" p="20px">
          <Center w="60px" h="60px" borderRadius="full" bg="green.600">
            <Icon as={PiPlant} boxSize="30px" color="white" />
          </Center>
          <Box>
            <Text fontSize="sm">Redução de CO₂</Text>
            <Editable
              value={
                <Heading size="lg" whiteSpace="nowrap">
                  {formData.co2ReductionInKg?.toLocaleString() || 0} kg
                </Heading>
              }
              EditableComponent={
                <InputGroup size="sm">
                  <Input
                    as={InputCurrency}
                    precision="2"
                    value={formData.co2ReductionInKg}
                    onChange={(co2ReductionInKg) => setFormData((state) => ({ ...state, co2ReductionInKg }))}
                  />
                  <InputRightElement>kg</InputRightElement>
                </InputGroup>
              }
              isEditable={isEditable}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={3} as={HStack} spacing={4} justifyContent="flex-start" borderWidth="1px" borderRadius="lg" p="20px">
          <Center w="60px" h="60px" borderRadius="full" bg="gray.100">
            <Icon as={MdAttachMoney} boxSize="30px" />
          </Center>
          <Box>
            <Text fontSize="sm">Valor total</Text>
            <Heading size="lg">{currency(formData.receivableAmount)}</Heading>
          </Box>
        </GridItem>

        <GridItem colSpan={3} as={VStack} spacing={0} justifyContent="center" borderWidth="1px" borderRadius="lg" p="20px">
          <Text fontSize="sm">Modalidade de fatura</Text>
          <Text fontSize="sm" fontWeight="semibold">
            {formData.isUnified ? "Fatura unificada" : "Fatura separada"}
          </Text>
        </GridItem>
        <GridItem colSpan={3} as={VStack} spacing={0} justifyContent="center" borderWidth="1px" borderRadius="lg" p="20px">
          <Text fontSize="sm">Grupo tarifário</Text>
          <Text fontSize="sm" fontWeight="semibold">
            {formData.tariffGroup?.title || "-"}
          </Text>
        </GridItem>
        <GridItem colSpan={3} as={VStack} spacing={0} justifyContent="center" borderWidth="1px" borderRadius="lg" p="20px">
          <Text fontSize="sm">Desconto acordado</Text>
          <Text fontSize="sm" fontWeight="semibold">
            {percent(formData.agreedDiscount?.percentage)} • {currency(formData.agreedDiscount?.economyAmount)}
          </Text>
        </GridItem>
        <GridItem colSpan={3} as={HStack} spacing={0} justifyContent="center" borderWidth="1px" borderRadius="lg" p="20px">
          <VStack spacing={0} justifyContent="center" flex="1">
            <Text fontSize="xs">Promoção</Text>
            <Text fontSize="sm">{formData.promotionDiscount?.promotion?.title || "-"}</Text>
            {formData.promotionDiscount && (
              <>
                <Text fontSize="sm" fontWeight="semibold">
                  {percent(formData.promotionDiscount?.percentage)} • {currency(formData.promotionDiscount?.economyAmount)}
                </Text>
                <Text fontSize="xs">
                  Utilização • {formData.promotionDiscount?.usageInMonths} de {formData.promotionDiscount?.durationInMonths}
                </Text>
              </>
            )}
          </VStack>
          {isEditable && (
            <ModalSelect
              selected={formData.promotionDiscount}
              loadOptions={handleLoadPromotions}
              onChange={handleChangePromotion}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
              isClearable={true}
            />
          )}
        </GridItem>
      </Grid>

      {formData.promotion && (
        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem
            colSpan={12}
            as={HStack}
            justifyContent="center"
            borderWidth="1px"
            borderRadius="lg"
            p="20px"
            bg="purple.500"
            color="white"
          >
            <Center w="40px" h="40px" borderRadius="lg" bg="purple.400">
              <Icon as={TbRosetteDiscount} boxSize="20px" />
            </Center>
            <VStack flex="1" spacing={0} alignItems="flex-start">
              <Text fontSize="xs">Promoção</Text>
              <Heading size="sm">{formData.promotion.title}</Heading>
            </VStack>
            <IconButton
              size="sm"
              colorScheme="purple"
              as={RouterLink}
              icon={<Icon as={TbExternalLink} />}
              to={`/promotions/edit/${formData.promotion._id}`}
              target="_blank"
            />
          </GridItem>
        </Grid>
      )}

      {formData.type === "manual" && (
        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={12} as={VStack} spacing={0} justifyContent="center" borderWidth="1px" borderRadius="lg" p="20px">
            <Text fontSize="sm">Energia ativa CEMIG</Text>
            <Editable
              value={
                <Heading size="md" whiteSpace="nowrap">
                  {formData.activePowerQttInKwh?.toLocaleString() || 0} kWh
                </Heading>
              }
              EditableComponent={
                <InputGroup size="sm">
                  <Input
                    as={InputCurrency}
                    precision="0"
                    value={formData.activePowerQttInKwh}
                    onChange={(activePowerQttInKwh) => setFormData((state) => ({ ...state, activePowerQttInKwh }))}
                  />
                  <InputRightElement>kWh</InputRightElement>
                </InputGroup>
              }
              isEditable={isEditable}
            />
          </GridItem>
        </Grid>
      )}

      <Items />

      <Divider my={6} />

      {formData.type === "automatic" && <Distributor />}
    </>
  );
};

export default Trader;
