import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Spinner,
  Switch,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Breadcrumb, PermissionedContainer, CustomTab } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle, usePermissioned, useNewTabNavigate } from "hooks";
import { messages } from "consts";
import { api, yup } from "lib";
import { MdBlock, MdCheck, MdChevronLeft, MdError, MdHistory } from "react-icons/md";
import { BsGrid, BsGrid3X3Gap } from "react-icons/bs";
import { TbExternalLink, TbFileInvoice } from "react-icons/tb";
import { Content } from "pages/Private/Container";
import General from "./general";
import Complementary from "./complementary";
import LegalRepresentatives from "./legalRepresentatives";
import ConsumerUnits from "./consumerUnits";
import Indications from "./indications";
import Comments from "./comments";
import Files from "./files";
import CemigFiles from "./cemigFiles";
import Contracts from "./contracts";
import CustomersDetailsContext from "./context";
import { PrivateContext } from "pages/Private";
import { HiOutlineBolt } from "react-icons/hi2";
import { BiSupport } from "react-icons/bi";

const tabHashs = [
  "#general",
  "#consumer-units",
  "#contracts",
  "#files",
  "#legal-representatives",
  "#comments",
  "#cemig-files",
  "#complementary",
  "#indications",
];

export const ContactVerify = ({ type, value = "", isVerified = false, isDisabled }) => {
  const { setFormData } = useContext(CustomersDetailsContext);

  const handleVerify = useCallback(() => {
    const switchVerify = (obj, key) => {
      const [prev, next] = (() => {
        if (type === "phone") return [obj[key]?.replace(/\D/g, ""), value.replace(/\D/g, "")];
        return [obj[key], value];
      })();
      if (prev !== next) return;
      obj[key.concat("Verified")] = !isVerified;
    };
    setFormData((state) => {
      const tmp = { ...state };
      for (const key of ["phone1", "phone2", "phone3", "email"]) switchVerify(tmp, key);
      for (const billingContact of tmp.billingContacts || []) for (const key of ["phone", "email"]) switchVerify(billingContact, key);
      return tmp;
    });
  }, [type, value, isVerified, setFormData]);

  return (
    <PermissionedContainer required="customers.verifyContact">
      <Tooltip label={isVerified ? "Verificado" : "Não verificado"}>
        <IconButton
          size="md"
          colorScheme={isVerified ? "green" : "gray"}
          icon={<Icon as={isVerified ? MdCheck : MdBlock} />}
          onClick={handleVerify}
          isDisabled={isDisabled}
        />
      </Tooltip>
    </PermissionedContainer>
  );
};

export const CustomersDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar cliente" : "Novo cliente");
  const { currentUser } = useContext(PrivateContext);
  const navigate = useNavigate();
  const newTabNavigate = useNewTabNavigate();
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(() => {
    const index = tabHashs.indexOf(location.hash);
    if (index !== -1) return index;
    return 0;
  });
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/customers/${_id}` }), [_id]));
  const isAllowed = {
    customersUpdate: usePermissioned("customers.update.*"),
    consumerUnitsRead: usePermissioned("customers.consumerUnits.read"),
    contractsRead: usePermissioned("customers.contracts.read"),
    filesRead: usePermissioned("customers.files.read"),
    legalRepresentativesRead: usePermissioned("customers.legalRepresentatives.read"),
    commentsRead: usePermissioned("customers.comments.read"),
    cemigFilesRead: usePermissioned("customers.cemigFiles.read"),
    indicationsRead: usePermissioned("customers.indications.read"),
  };
  const [consumerUnits, isLoadingConsumerUnits, refreshConsumerUnits] = useFetchData(
    useMemo(
      () => ({
        path: `/private/customers/${_id}/consumer-units`,
        params: { sort: { cemigInstallationNumber: 1 }, perPage: -1 },
        options: { isEnabled: _.isString(_id) && isAllowed.consumerUnitsRead },
      }),
      [_id, isAllowed.consumerUnitsRead]
    )
  );
  const [legalRepresentatives, isLoadingLegalRepresentatives, refreshLegalRepresentatives] = useFetchData(
    useMemo(
      () => ({
        path: `/private/customers/${_id}/legal-representatives`,
        params: { sort: { name: 1 }, perPage: -1 },
        options: { isEnabled: _.isString(_id) && isAllowed.legalRepresentativesRead },
      }),
      [_id, isAllowed.legalRepresentativesRead]
    )
  );
  const [counters, setCounters] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isInvalid, setIsInvalid] = useState({});
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isAdvancedMode, onToggle: onToggleAdvancedMode } = useDisclosure();
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const isAllowedUpdateAfterFinished = usePermissioned("customers.consumerUnits.updateAfterContractAdded");
  const isSaveDisabled = useMemo(() => {
    if (formData.contractsCount >= 1 && !isAllowedUpdateAfterFinished) return true;
    return false;
  }, [formData.contractsCount, isAllowedUpdateAfterFinished]);
  const toast = useCustomToast();

  useEffect(() => {
    if (_.isUndefined(data) === false) {
      const formData = data ?? {
        name: "",
        phone1Category: "whatsapp",
        phone2Category: "whatsapp",
        phone3Category: "whatsapp",
        earlyWarning: "180",
        consultant: {
          _id: currentUser._id,
          name: currentUser.name,
        },
        primaryContact: {
          phoneCategory: "whatsapp",
        },
        billingContacts: [{ phoneCategory: "whatsapp" }],
        isActive: true,
      };
      if (formData.birth) formData.birth = moment(formData.birth).format("DD/MM/YYYY");
      setFormData(formData);
    }
  }, [currentUser._id, currentUser.name, data]);

  const isEditDisabled = useCallback(
    (key) => {
      if (_.isString(_id)) return !isAllowed.customersUpdate[key];
      return false;
    },
    [_id, isAllowed.customersUpdate]
  );

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/customers/${_id}`, data) : await api.put("/private/customers", data);
        navigate(`/customers/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const billingContactSchema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        sendingMethod: yup.string().required(messages.error.required),
        phone: yup.string().when("sendingMethod", {
          is: "whatsapp",
          then: yup.string().required(messages.error.required).isValidPhoneNumber(),
        }),
        email: yup.string().when("sendingMethod", {
          is: "email",
          then: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
        }),
      });

      const shape = {
        type: yup.string().required(messages.error.required),
        name: yup.string().isValidName().required(messages.error.required),
        document: yup.string().required(messages.error.required),
        birth: yup.date().when("type", {
          is: "pf",
          then: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        }),
        phone1: yup.string().required(messages.error.required).isValidPhoneNumber(),
        phone2: yup.string().isValidPhoneNumber(),
        phone3: yup.string().isValidPhoneNumber(),
        email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
        address: yup.object().shape({
          zipCode: yup.string().required(messages.error.required),
          street: yup.string().required(messages.error.required),
          number: yup.string().required(messages.error.required),
          neighborhood: yup.string().required(messages.error.required).max(20, messages.error.maxLength(20)),
          city: yup.string().required(messages.error.required),
          state: yup.string().required(messages.error.required),
        }),
        billingContacts: yup.array().of(billingContactSchema).min(1, messages.error.required),
        earlyWarning: yup.string().required(messages.error.required),
        cemigEmail: yup.string().email(messages.error.invalidEmail),
        consultant: yup.string().required(messages.error.required),
      };

      if (!formData._id || moment(formData.createdAt).toDate() >= moment("2024-08-01").toDate())
        shape.exhibition = yup.string().required(messages.error.required);

      const schema = yup.object().shape(shape);
      const data = {
        ...formData,
        birth: formData.birth && moment(formData.birth, "DD/MM/YYYY").toDate(),
        indicatedBy: formData.indicatedBy?._id ?? null,
        consultant: formData.consultant?._id,
        exhibition: formData.exhibition?._id,
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <CustomersDetailsContext.Provider
      value={{
        isLoadingData,
        formData,
        setFormData,
        formErrors,
        isAdvancedMode,
        setIsInvalid,
        setCounters,
        setIsLoading,
        consumerUnits,
        isLoadingConsumerUnits,
        refreshConsumerUnits,
        legalRepresentatives,
        isLoadingLegalRepresentatives,
        refreshLegalRepresentatives,
        isEditDisabled,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/customers")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/customers", label: "clientes" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <HStack>
            {_.size(consumerUnits?.data) && (
              <PermissionedContainer required="powerManagement.read">
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<Icon as={HiOutlineBolt} />}
                  rightIcon={<Icon as={TbExternalLink} />}
                  onClick={() =>
                    newTabNavigate("/power-management", {
                      installationNumber: _(consumerUnits?.data).map("cemigInstallationNumber").join(","),
                    })
                  }
                >
                  gestão de energia
                </Button>
              </PermissionedContainer>
            )}
            {_id && (
              <PermissionedContainer required="invoices.read">
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<Icon as={TbFileInvoice} />}
                  rightIcon={<Icon as={TbExternalLink} />}
                  onClick={() => newTabNavigate("/invoices", { customer: [{ _id, name: data?.name }] })}
                >
                  faturas ({formData.invoicesCount?.toLocaleString() || 0})
                </Button>
              </PermissionedContainer>
            )}
            <Button
              size="sm"
              variant="outline"
              leftIcon={<Icon as={isAdvancedMode ? BsGrid : BsGrid3X3Gap} />}
              onClick={onToggleAdvancedMode}
            >
              modo {isAdvancedMode ? "simplificado" : "avançado"}
            </Button>
            {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
          </HStack>
        </HStack>

        {formData.sentToCollectionAgency && (
          <HStack bg="purple.100" p="15px" borderRadius="lg" my={4}>
            <Center bg="purple.500" w="40px" h="40px" borderRadius="full" color="white">
              <Icon as={BiSupport} boxSize="25px" />
            </Center>
            <Box flex="1">
              <Heading size="sm">Cliente em cobrança terceirizada</Heading>
              <Text fontSize="xs">
                Este cliente foi enviado para cobrança terceirizada em{" "}
                {moment(formData.sentToCollectionAgencyAt).format("DD/MM/YYYY [às] HH:mm:ss")} por{" "}
                {formData.sentToCollectionAgencyBy?.name || "-"}
              </Text>
            </Box>
          </HStack>
        )}

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Cliente</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
            {formData.lead && (
              <Box>
                <Button
                  size="sm"
                  variant="outline"
                  rightIcon={<Icon as={TbExternalLink} />}
                  as={RouterLink}
                  to={`/leads/edit/${formData.lead}`}
                  target="_blank"
                >
                  lead de origem
                </Button>
              </Box>
            )}
          </HStack>
        </HStack>

        <Tabs colorScheme="main" index={activeTabIndex} onChange={(index) => setActiveTabIndex(index)}>
          <TabList overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
            <CustomTab title="dados gerais" />
            {isAllowed.consumerUnitsRead && (
              <CustomTab title="unidades consumidoras" isLoading={isLoading.consumerUnits} count={counters.consumerUnits || 0} />
            )}
            {isAllowed.contractsRead && <CustomTab title="contratos" isLoading={isLoading.contracts} count={counters.contracts || 0} />}
            {isAllowed.filesRead && (
              <CustomTab
                title="arquivos"
                isLoading={isLoading.files}
                count={counters.files || 0}
                RightComponent={
                  isInvalid.files && (
                    <Tooltip label="O consultor ainda não adicionou os documentos deste cliente.">
                      <IconButton size="xs" colorScheme="red" icon={<Icon as={MdError} />} isRound />
                    </Tooltip>
                  )
                }
              />
            )}
            {isAllowed.legalRepresentativesRead && (
              <CustomTab
                title="representantes legais"
                isLoading={isLoading.legalRepresentatives}
                count={counters.legalRepresentatives || 0}
                style={formData.type === "pf" ? { position: "absolute", top: -9999 } : {}}
              />
            )}
            {isAllowed.commentsRead && <CustomTab title="observações" isLoading={isLoading.comments} count={counters.comments || 0} />}
            {isAdvancedMode && (
              <>
                {isAllowed.cemigFilesRead && (
                  <CustomTab title="arquivos CEMIG" isLoading={isLoading.cemigFiles} count={counters.cemigFiles || 0} />
                )}
                <CustomTab title="dados complementares" />
                {isAllowed.indicationsRead && (
                  <CustomTab title="indicações" isLoading={isLoading.indications} count={counters.indications || 0} />
                )}
              </>
            )}
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            {isAllowed.consumerUnitsRead && (
              <TabPanel px="0" py="30px">
                <ConsumerUnits />
              </TabPanel>
            )}
            {isAllowed.contractsRead && (
              <TabPanel px="0" py="30px">
                <Contracts />
              </TabPanel>
            )}
            {isAllowed.filesRead && (
              <TabPanel px="0" py="30px">
                <Files />
              </TabPanel>
            )}
            {isAllowed.legalRepresentativesRead && (
              <TabPanel px="0" py="30px">
                <LegalRepresentatives />
              </TabPanel>
            )}
            {isAllowed.commentsRead && (
              <TabPanel px="0" py="30px">
                <Comments />
              </TabPanel>
            )}
            {isAllowed.cemigFilesRead && (
              <TabPanel px="0" py="30px">
                <CemigFiles />
              </TabPanel>
            )}
            <TabPanel px="0" py="30px">
              <Complementary />
            </TabPanel>
            {isAllowed.indicationsRead && (
              <TabPanel px="0" py="30px">
                <Indications />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Content>

      <PermissionedContainer required={"customers.".concat(_id ? "update.*" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            {!isSaveDisabled && (
              <Button
                size="sm"
                colorScheme="main"
                isDisabled={!formData.type}
                isLoading={isLoadingData || isLoadingSaveData}
                onClick={handleSubmit}
              >
                salvar
              </Button>
            )}
            <Button size="sm" variant="ghost" onClick={() => navigate("/customers")}>
              {isSaveDisabled ? "fechar" : "cancelar"}
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory path={`/private/customers/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </CustomersDetailsContext.Provider>
  );
};
