import React, { useCallback, Fragment, useContext, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  MenuItem,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { api, yup } from "lib";
import { messages } from "consts";
import InvoicesDetailsContext from "./context";
import { FaBarcode } from "react-icons/fa";
import InputMask from "react-input-mask";
import { useCustomToast } from "hooks";
import { useParams } from "react-router-dom";

const AddDigitableLine = () => {
  const { _id } = useParams();
  const { handleRefreshData } = useContext(InvoicesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/invoices/${_id}/digitable-line`, data);
        handleRefreshData();
        onClose();
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        digitableLine: yup.string().length(48, messages.error.length(48)).required(messages.error.required),
      });
      const data = { ...formData, digitableLine: formData.digitableLine.replace(/\D/g, "") };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Fragment>
      <PermissionedContainer required="invoices.addDigitableLine">
        <MenuItem onClick={onOpen}>
          <HStack>
            <Icon as={FaBarcode} />
            <Text>adicionar código de barras CEMIG</Text>
          </HStack>
        </MenuItem>
      </PermissionedContainer>

      <AlertDialog size="xl" isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Adicionar código de barras CEMIG</AlertDialogHeader>
          <AlertDialogBody>
            <FormControl isRequired={true} isInvalid={formErrors.digitableLine}>
              <FormLabel fontSize="sm" mb="4px">
                Linha digitável
              </FormLabel>
              <Input
                as={InputMask}
                mask="999999999999 999999999999 999999999999 999999999999"
                value={formData.digitableLine || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, digitableLine: target.value }))}
              />
              <FormErrorMessage>{formErrors.digitableLine}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="green" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              adicionar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

export default AddDigitableLine;
