import moment from "moment";
import _ from "lodash";
import { Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { currency, masks, percent, locale } from "lib";
import { translator } from "lib";
import { StatusBadge } from "components";

const electricityBalanceAndConsume = _.flatten(
  _.range(1, 5).map((count, index) => {
    return [
      {
        accessor: `lastsElectricityConsumes.${index}.referenceDate`,
        title: `Últ. consumos/Ref (${count})`,
        isVisible: false,
        formatter: ({ lastsElectricityConsumes = [] }) =>
          lastsElectricityConsumes[index] ? moment(lastsElectricityConsumes[index].referenceDate).format("MMM/YYYY").toUpperCase() : "-",
      },
      {
        accessor: `lastsElectricityConsumes.${index}.consumedPowerQttInKwh`,
        title: `Últ. consumos/kWh (${count})`,
        isVisible: false,
        render: ({ lastsElectricityConsumes }) => locale(lastsElectricityConsumes?.[index]?.consumedPowerQttInKwh || 0, { precision: 0 }),
        exporter: ({ lastsElectricityConsumes }) =>
          locale(lastsElectricityConsumes?.[index]?.consumedPowerQttInKwh || 0, { precision: 0, compact: true }),
      },
    ];
  })
);

const defaultColumns = [
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    isSortable: false,
    render: ({ status }) => <StatusBadge {...status} />,
    exporter: ({ status }) => status?.title,
  },
  {
    accessor: "substatus",
    title: "Substatus",
    isVisible: true,
    isSortable: false,
    render: ({ substatus }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return <StatusBadge {...substatus} />;
      return "****";
    },
    exporter: ({ substatus }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return substatus?.title || "-";
      return "****";
    },
  },
  {
    accessor: "customer.nid",
    title: "NID/Cliente",
    isVisible: false,
    isSortable: false,
    formatter: ({ customer }) => customer.nid,
  },
  {
    accessor: "customer.name",
    title: "Nome/Cliente",
    isVisible: true,
    isSortable: false,
    render: ({ customer }) => (
      <Link as={RouterLink} to={`/customers/edit/${customer._id}`}>
        {customer.name}
      </Link>
    ),
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return customer.name;
      return "****";
    },
  },
  {
    accessor: "segment",
    title: "Segmento",
    isVisible: true,
    formatter: ({ segment }) => segment?.title ?? "-",
  },
  {
    accessor: "customer.type",
    title: "Tipo pessoa/Cliente",
    isVisible: false,
    isSortable: false,
    formatter: ({ customer }) => (customer?.type === "pj" ? "Pessoa jurídica" : "Pessoa física"),
  },
  {
    accessor: "cemigCustomerNumber",
    title: "Num. cliente CEMIG",
    isVisible: false,
    isSortable: false,
    render: ({ cemigCustomerNumber }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.cemigCustomerNumber) return cemigCustomerNumber;
      return masks.obfuscate(cemigCustomerNumber);
    },
    exporter: ({ cemigCustomerNumber }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.cemigCustomerNumber) return cemigCustomerNumber;
      return masks.obfuscate(cemigCustomerNumber);
    },
  },
  {
    accessor: "cemigInstallationNumber",
    title: "Num. instalação",
    isVisible: true,
    isSortable: false,
    render: ({ customer, cemigInstallationNumber }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.cemigInstallationNumber)
        return (
          <Link as={RouterLink} to={`/customers/edit/${customer._id}/#consumer-units`}>
            {cemigInstallationNumber}
          </Link>
        );
      return masks.obfuscate(cemigInstallationNumber);
    },
    exporter: ({ cemigInstallationNumber }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.cemigInstallationNumber) return cemigInstallationNumber;
      return masks.obfuscate(cemigInstallationNumber);
    },
  },
  {
    accessor: "contract.uuid",
    title: "UUID/Contrato",
    isVisible: true,
    isSortable: false,
    render: ({ customer, contract }) =>
      contract?.uuid ? (
        <Link as={RouterLink} to={`/customers/edit/${customer._id}/#contracts`}>
          <Text w="100px" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {contract.uuid}
          </Text>
        </Link>
      ) : (
        "-"
      ),
    exporter: ({ contract }) => contract?.uuid,
  },
  {
    accessor: "contract.status",
    title: "Status/Contrato",
    isVisible: true,
    isSortable: false,
    render: ({ contract }) => (contract?.status ? <StatusBadge schema="contracts" status={contract.status} /> : "-"),
    exporter: ({ contract }) => translator(contract?.status),
  },
  {
    accessor: "contract.signers",
    title: "E-mail/Contrato",
    isVisible: true,
    isSortable: false,
    render: ({ contract }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return contract?.signers?.[0]?.email || "-";
      return "****";
    },
    exporter: ({ contract }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return contract?.signers?.[0]?.email || "-";
      return "****";
    },
  },
  {
    accessor: "contract.sendedAt",
    title: "Data de envio/Contrato",
    isVisible: true,
    isSortable: false,
    render: ({ contract }) => (contract?.sendedAt ? moment(contract.sendedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
    exporter: ({ contract }) => (contract?.sendedAt ? moment(contract.sendedAt).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "contract.finishedAt",
    title: "Data de assinatura/Contrato",
    isVisible: true,
    isSortable: false,
    render: ({ contract }) => (contract?.finishedAt ? moment(contract.finishedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
    exporter: ({ contract }) => (contract?.finishedAt ? moment(contract.finishedAt).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "customer.document",
    title: "Documento/Cliente",
    isVisible: false,
    isSortable: false,
    render: ({ customer }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.document(customer.document);
      return "****";
    },
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return `'${customer?.document || "-"}`;
      return "****";
    },
  },
  {
    accessor: "customer.email",
    title: "E-mail/Cliente",
    isVisible: false,
    isSortable: false,
    render: ({ customer }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return customer.email;
      return "****";
    },
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return customer.email;
      return "****";
    },
  },
  {
    accessor: "customer.phone1",
    title: "Telefone1/Cliente",
    isVisible: false,
    isSortable: false,
    render: ({ customer }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.phone(customer.phone1);
      return "****";
    },
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return masks.phone(customer.phone1);
      return "****";
    },
  },
  {
    accessor: "customer.phone2",
    title: "Telefone2/Cliente",
    isVisible: false,
    isSortable: false,
    render: ({ customer }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.phone(customer.phone2);
      return "****";
    },
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return masks.phone(customer.phone2);
      return "****";
    },
  },
  {
    accessor: "customer.phone3",
    title: "Telefone3/Cliente",
    isVisible: false,
    isSortable: false,
    render: ({ customer }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.phone(customer.phone3);
      return "****";
    },
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return masks.phone(customer.phone3);
      return "****";
    },
  },
  {
    accessor: "customer.exhibition.title",
    title: "Evento/Cliente",
    isVisible: false,
    isSortable: false,
    formatter: ({ customer }) => customer.exhibition?.title || "-",
  },
  {
    accessor: "customer.customerCommentsCount",
    title: "Obs./Cliente",
    isVisible: false,
    isSortable: false,
    formatter: ({ customer }) => (customer.customerCommentsCount >= 1 ? "Sim" : "Não"),
  },
  {
    accessor: "comments",
    title: "Obs. UC",
    isVisible: false,
    isSortable: false,
    formatter: ({ comments }) => (comments?.length >= 1 ? "Sim" : "Não"),
  },
  {
    accessor: "invoiceDueDay",
    title: "Dia venc. fatura",
    isVisible: true,
    formatter: ({ invoiceDueDay }) => invoiceDueDay ?? "-",
  },
  {
    accessor: "electricityBillAmount",
    title: "Valor da conta",
    isVisible: true,
    render: ({ electricityBillAmount }) => currency(electricityBillAmount),
    exporter: ({ electricityBillAmount }) => currency(electricityBillAmount, { compact: true }),
    renderFoot: ({ electricityBillAmount }) => currency(electricityBillAmount),
  },
  {
    accessor: "electricityAverageSpentInKwh",
    title: "Méd. contrato (kWh)",
    isVisible: true,
    render: ({ electricityAverageSpentInKwh }) => locale(electricityAverageSpentInKwh, { precision: 0 }),
    exporter: ({ electricityAverageSpentInKwh }) => locale(electricityAverageSpentInKwh, { precision: 0, compact: true }),
    renderFoot: ({ electricityAverageSpentInKwh }) => locale(electricityAverageSpentInKwh, { precision: 0 }),
  },
  {
    accessor: "checkedElectricityAverageSpentInKwh",
    title: "Méd. corrigida (kWh)",
    isVisible: true,
    render: ({ checkedElectricityAverageSpentInKwh }) => locale(checkedElectricityAverageSpentInKwh, { precision: 0 }),
    exporter: ({ checkedElectricityAverageSpentInKwh }) => locale(checkedElectricityAverageSpentInKwh, { precision: 0, compact: true }),
    renderFoot: ({ checkedElectricityAverageSpentInKwh }) => locale(checkedElectricityAverageSpentInKwh, { precision: 0 }),
  },
  {
    accessor: "netElectricityAverageSpentInKwh",
    title: "Méd. líquida (kWh)",
    isVisible: true,
    render: ({ netElectricityAverageSpentInKwh }) => locale(netElectricityAverageSpentInKwh, { precision: 0 }),
    exporter: ({ netElectricityAverageSpentInKwh }) => locale(netElectricityAverageSpentInKwh, { precision: 0, compact: true }),
    renderFoot: ({ netElectricityAverageSpentInKwh }) => locale(netElectricityAverageSpentInKwh, { precision: 0 }),
  },
  {
    accessor: "currentGenerationBalanceInKwh",
    title: "Saldo atual de geração (kWh)",
    isVisible: true,
    render: ({ currentGenerationBalanceInKwh }) => locale(currentGenerationBalanceInKwh, { precision: 0 }),
    exporter: ({ currentGenerationBalanceInKwh }) => locale(currentGenerationBalanceInKwh, { precision: 0, compact: true }),
  },
  {
    accessor: "installationType",
    title: "Tipo de instalação",
    isVisible: true,
    formatter: ({ installationType }) => installationType.toUpperCase(),
  },
  {
    accessor: "customerClass",
    title: "Classe do cliente",
    isVisible: true,
    formatter: ({ customerClass }) => customerClass.toUpperCase(),
  },
  {
    accessor: "approximateReadingDay",
    title: "Dia aprox. leitura",
    isVisible: true,
    formatter: ({ approximateReadingDay }) => approximateReadingDay ?? "-",
  },
  {
    accessor: "agreedDiscount",
    title: "Desconto",
    isVisible: true,
    formatter: ({ agreedDiscount }) => percent(agreedDiscount),
    renderFoot: ({ agreedDiscount }) => percent(agreedDiscount),
  },
  {
    accessor: "voltage",
    title: "Tensão",
    isVisible: true,
    formatter: ({ voltage }) => translator(voltage),
  },
  {
    accessor: "loyaltyTimeInMonths",
    title: "Fidelidade",
    isVisible: true,
    formatter: ({ loyaltyTimeInMonths }) => locale(loyaltyTimeInMonths),
    renderFoot: ({ loyaltyTimeInMonths }) => locale(loyaltyTimeInMonths),
  },
  {
    accessor: "earlyWarning",
    title: "Aviso prév.",
    isVisible: true,
    formatter: ({ customer }) => locale(customer?.earlyWarning),
    renderFoot: ({ earlyWarning }) => locale(earlyWarning),
  },
  {
    accessor: "economicRights",
    title: "Direitos econômicos",
    isVisible: true,
    isSortable: false,
    formatter: (item) => percent(item.economicRights, { precision: 5 }),
  },
  {
    accessor: "powerPlant.name",
    title: "Usina",
    isVisible: true,
    isSortable: false,
    formatter: ({ powerPlant }) => powerPlant?.name,
  },
  {
    accessor: "distributorAvailableCreditBalance",
    title: "Saldo crédito CEMIG",
    isVisible: true,
    formatter: (item) => currency(item.distributorAvailableCreditBalance),
  },
  {
    accessor: "address.zipCode",
    title: "CEP",
    isVisible: false,
    formatter: ({ address }) => address?.zipCode,
  },
  {
    accessor: "address.street",
    title: "Rua",
    isVisible: false,
    formatter: ({ address }) => address?.street,
  },
  {
    accessor: "address.number",
    title: "Número",
    isVisible: false,
    formatter: ({ address }) => address?.number,
  },
  {
    accessor: "address.complement",
    title: "Complemento",
    isVisible: false,
    formatter: ({ address }) => address?.complement,
  },
  {
    accessor: "address.neighborhood",
    title: "Bairro",
    isVisible: false,
    formatter: ({ address }) => address?.neighborhood,
  },
  {
    accessor: "address.city",
    title: "Cidade",
    isVisible: true,
    formatter: ({ address }) => address?.city,
  },
  {
    accessor: "address.state",
    title: "Estado",
    isVisible: false,
    formatter: ({ address }) => address?.state,
  },
  {
    accessor: "isChangeOwnershipNecessary",
    title: "TT?",
    isVisible: true,
    formatter: ({ isChangeOwnershipNecessary }) => (isChangeOwnershipNecessary ? "Sim" : "Não"),
  },
  {
    accessor: "isPortability",
    title: "Port.?",
    isVisible: true,
    formatter: ({ isPortability }) => (isPortability ? "Sim" : "Não"),
  },
  {
    accessor: "portabilityCompanyName",
    title: "Empresa origem",
    isVisible: false,
    formatter: ({ portabilityCompanyName }) => portabilityCompanyName ?? "-",
  },
  {
    accessor: "isUnifiedInvoice",
    title: "Modalidade de fatura",
    isVisible: false,
    formatter: ({ isUnifiedInvoice }) => (isUnifiedInvoice ? "Fatura unificada" : "Fatura separada"),
  },
  {
    accessor: "cemigPaymentMethod",
    title: "Método pag. CEMIG?",
    isVisible: false,
    formatter: ({ cemigPaymentMethod }) => translator(cemigPaymentMethod),
  },
  {
    accessor: "invoiceReceiptMethod",
    title: "Recebimento da fatura",
    isVisible: false,
    formatter: ({ invoiceReceiptMethod }) => translator(invoiceReceiptMethod),
  },

  {
    accessor: "currentElectricityBalance.currentPowerQttBalanceInKwh",
    title: "Saldo atual de energia (kWh)",
    isVisible: false,
    render: ({ currentElectricityBalance }) => locale(currentElectricityBalance?.currentPowerQttBalanceInKwh || 0, { precision: 0 }),
    exporter: ({ currentElectricityBalance }) =>
      locale(currentElectricityBalance?.currentPowerQttBalanceInKwh || 0, { precision: 0, compact: true }),
  },
  {
    accessor: "currentElectricityBalance.referenceDate",
    title: "Saldo atual de energia/Ref",
    isVisible: false,
    formatter: ({ currentElectricityBalance }) =>
      currentElectricityBalance?.referenceDate ? moment(currentElectricityBalance.referenceDate).format("MMM/YYYY").toUpperCase() : "-",
  },
  {
    accessor: "currentElectricityBalance.quota",
    title: "Saldo atual de energia/Quota",
    isVisible: false,
    formatter: ({ currentElectricityBalance }) => percent(currentElectricityBalance?.quota || 0, { precision: 5 }),
  },

  ...electricityBalanceAndConsume,

  {
    accessor: "isLowIncome",
    title: "Baixa renda?",
    isVisible: true,
    formatter: ({ isLowIncome }) => (isLowIncome ? "Sim" : "Não"),
  },
  {
    accessor: "paymentProfile.title",
    title: "Perfil pagamento",
    isVisible: true,
    isSortable: false,
    formatter: ({ paymentProfile }) => paymentProfile?.title || "-",
  },
  {
    accessor: "firstBankSlipIssue.status",
    title: "Status prim. fat.",
    isVisible: true,
    isSortable: false,
    render: ({ firstBankSlipIssue }) => <StatusBadge schema="invoices" status={firstBankSlipIssue?.status} />,
    exporter: ({ firstBankSlipIssue }) => translator(firstBankSlipIssue?.status),
  },
  {
    accessor: "firstBankSlipIssue.dueDate",
    title: "Venc. prim. fat.",
    isVisible: true,
    isSortable: false,
    formatter: ({ firstBankSlipIssue }) => (firstBankSlipIssue?.dueDate ? moment(firstBankSlipIssue?.dueDate).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "firstBankSlipIssue.paidAt",
    title: "Dt. pg. prim. fat.",
    isVisible: true,
    isSortable: false,
    formatter: ({ firstBankSlipIssue }) => (firstBankSlipIssue?.paidAt ? moment(firstBankSlipIssue?.paidAt).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    formatter: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "customer.consultant.name",
    title: "Consultor",
    isVisible: true,
    formatter: ({ customer }) => customer?.consultant?.name ?? "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: false,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
